// Here you can add other styles
$primary-custom-color: #112d3c;
$primary-custom-text-color: white;
$secondary-custom-text-color: white;
$menu-custom-text-primary-color: #002d62;
$secondary-custom-color: #AE8C46;

$light--custom-color: #f3eee7;
$fontUrl:"../assets/font/AvenirNextLTPro-Regular.otf";


// Disabilitazione navbar bloccata nello scroll per i device mobile
@media (max-width: 991.98px) {
  .app-body {
    margin-top: unset;
  }
}

@media (max-width: 991.98px){
  .app-header {
    position:unset;
  }
}

@font-face {
  font-family: "customfont";
  src: url($fontUrl);
}

body, html {
  font-family: 'customfont';
}



.form-control{
  padding-left:5px;
}

.was-validated .form-control:valid, .form-control.is-valid , {
  background-image: unset;
  padding-right:unset;

}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: unset;
  padding-right:unset;

}

.tab-content {
  border:unset;
}
.nav.nav-tabs > .nav-link.active{
  color: #2f353a;
  background: #fff;
  border-color: #c8ced3;
  border-bottom-color: #fff;

}


.nav.nav-tabs > .nav-item {
  //border:1px solid;
  //border-color: $primary-custom-color;
  margin-right: 7px;
  background-color: whitesmoke;
}

.nav-tabs .nav-link.active {
  background:unset;
  border-color:unset;
}

.nav-link-danger.nav-link.active{
  background-color: #ffffff!important;
  color:black!important;
}


.nav-title{
  color: black!important;
}

.nav-link.active{
  background-color: $primary-custom-color!important;
  color:$primary-custom-text-color!important;
  border-color: #c8ced3;
  border-bottom-color: #fff;

}

.nav-link{
  color:$menu-custom-text-primary-color!important;
}


.sidebar .nav-link.active .nav-icon {
  color: $secondary-custom-color;
}


.nav-link.nav-link-danger:hover, .nav-link:hover  {
  background-color: $primary-custom-color!important;
  color: $primary-custom-text-color!important;
}




.app-header.navbar{
  background-color: white!important;
}

.sidebar-minimizer.mt-auto{
  background-color: $primary-custom-color!important;
}


.navbar-toggler-icon {
  height: 23px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='darkgoldenrod' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")!important;
}

.buttonPrimaryCustomColor{
  background-color: $primary-custom-color;
  color:$primary-custom-text-color;
}

.buttonPrimaryCustomColor:hover{
  background-color: #f3eee7;
  color:$primary-custom-color;
}

.borderPrimaryCustomColor{
  border-color: $primary-custom-color;
}

.buttonSecondaryCustomColor{
  background-color: $secondary-custom-color;
  color:$secondary-custom-text-color;
}

.buttonSecondaryCustomColor:hover{
  background-color: #f3eee7;
  color:$primary-custom-color;
}

.borderSecondaryCustomColor{
  border-color: $secondary-custom-color;
}

.switch-primary .switch-input:checked + .switch-slider {
  background-color: $primary-custom-color!important;
  border-color: #c8ced3!important;
}

.switch-primary .switch-input:checked + .switch-slider::before {
  border-color: #c8ced3;
}

.bold{
  font-style: bold;
}
.font-weight-bold{
  font-weight: 700!important;
}

.italic{
  font-style: italic;
}

.alignLeft{
  text-align: left;
}
.alignRight{
  text-align: right;
}

.alignCenter{
  text-align: center;
}

.verticalAlignMiddle{
  margin-top : auto;
  margin-bottom : auto;
}
.marginBottom10{
  margin-bottom : 10px;
}

.marginLRauto{
  margin-left : auto;
  margin-right : auto;
}

.marginX10{
  margin-left : 10px;
  margin-right : 10px;
}

.media-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 1.25rem;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.mt-3, .my-3 {
  margin-top: 1.25rem!important;
}

.mr-3, .mx-3 {
  margin-right: 1.25rem!important;
}

.position-relative {
  position: relative!important;
}

.no-padding{
  padding:0px
}


.buttonColored{
  background-color: #ff0000;
  borderColor: #ff0000!important;
  color: #ffffff;
}



.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 98%;
  padding: 0 8px;
  //border: 1px solid rgb(214, 216, 218);
  //border-radius: 6px;
  &:focus-within {
    // border: 1px solid #0052cc;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
    li {
      width: auto;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 0 8px;
      font-size: 14px;
      list-style: none;
      border-radius: 6px;
      margin: 0 8px 8px 0;
      background: #0052cc;
      span {
        margin-top: 3px;
      }
      i {
        font-size: 14px;
        margin-left: 15px;
        color: #fff;
        border-radius: 10%;
        //background: #fff;
        cursor: pointer;
      }
    }
  }
  input {
    flex:1;
    //border: none;
    height: 46px;
    width: 95%;
    font-size: 14px;
    //padding: 4px 0 0 0;
    padding-bottom: 15px;
    padding-top: 15px;
    &:focus {
      outline: transparent;
    }
  }
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}


.tabCustom{

  border: 1px solid;
  border-top-color: transparent;
  border-bottom-color: #c8ced3;
  border-left-color: transparent;
  border-right-color: transparent;

}



.tabCustom.active{
  //border: 1px solid #c8ced3;
  //border-bottom-color: transparent;
  background-color:red;
  color: white!important;
}

.tabCustom.active > .nav-link{
  color: white!important;
}


table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret, table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.btn-no-background{
  background-color: unset;
}

.btn-background-white{
  background-color: white;
}

.alignSelfCentered{
  align-self: center;
}

.row .alignSelfCentered > div{
  text-align: center;
}

.textCentered{
  text-align: center;
}

.marginHr{
  margin-top:0.3rem;
  margin-bottom:0.3rem;
}

.fontCustom{
  font-size: medium!important;
}

.rc-time-picker-input, .rc-time-picker-panel-input{
  font-size: large!important;
}


.rc-time-picker-panel-select {
  width: 69px!important;
}

.rc-time-picker-panel-select > ul > li {
  font-size: large!important;
  height: 40px;
}

.rc-time-picker-panel-select-option-selected{
  font-size: large!important;
}

.rc-time-picker-panel-narrow {
  max-width: 140px!important;
}

.main .container-fluid{
  padding: 0px 8px;
}

/*.cardMenu{
  margin: 1px 10px 10px 0;
  width: calc(25% - 10px);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}*/

.cardMenu {
  display: inline-block;
  position: relative;
  //width: 24%;
  //min-width: 160px;
  //margin: 0 1% 1% 0;
  //float: left;
  font-size: 14px;
}

@media (min-width: 1025px) {
  .cardMenu {
    width: 19%;
  }
  .cardBodyCarrello{
    height: calc(100vh - 180px);
  }
}

@media (max-width: 1024px) {
  .cardMenu {
    width: 24%;
  }
  .cardBodyCarrello{
    height: calc(100vh - 170px);
  }
}

@media
(orientation: portrait) {
  .cardMenu {
    width: 48%;
  }


}

.cardMenu:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* initial ratio of 1:1*/
}

.cardMenu:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.70), 0 10px 10px rgba(0,0,0,0.22);
}

.cardContent {

  /* Positioning */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* Styling */
  //background: black;
  padding: 15px;
  text-align: center;
  vertical-align: center;
  text-transform: uppercase;
  //color: #fff;
}

.cardMenuFooter{
  border-bottom-left-radius: 1rem!important;
  border-bottom-right-radius: 1rem!important;
  background-color: whitesmoke!important;
}

.flexSpace{
  display: flex;
  justify-content: space-between;
}

.flexCenter{
  display: flex;
  justify-content: center;
}

.flexDirectionColumns{
  flex-direction: column;
}

.fontSizeLarge{
  font-size: large;
}

.fontSizeSmall{
  font-size: small;
}

.fontSizeMedium{
  font-size: medium;
}

.box{
  position: relative;
  background-color: whitesmoke;
  padding: 10px;
  border: 1px solid #d8d8d8;
  margin: 10px 0px 0px 0px;
  height: 100%;
  overflow-y: scroll;
}

.colPaddingCustom{
  padding-right: 0px;
  padding-left: 15px;
}

.badge-info {
  color: white;
}

.marginBottom5{
  margin-bottom: 5px;
}

.verticalAlignMiddleA{
  vertical-align: middle;
}


.imageContainer {
  width: 100%;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: 50% 50%;
}

.imageExternal {
  position:relative;
  padding: 10px;
  background: white;
  overflow: auto;
}

.printMargin{
  padding:20px;
  max-width:200px
}

/* override styles when printing */
@media print {

.printContainer{
  width:8cm;
  color:black;
  font-family: "Lucida Console", Courier, monospace;
}

  .printStyle{
    color:black;
    padding:3px;
    width:100%
  }
}


.sortableHelper {
  z-index: 10000 !important;
}
